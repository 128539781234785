.footer {
  color: #fff;
  // background: #333;
  background: rgb(37, 36, 36);
  padding-bottom: 80px;

  @media (min-width: $breakpoint_lg) {
    padding-bottom: 100px;
  }

  &__wrapper {
    padding: 30px 15px;

    @media (min-width: $breakpoint_md) {
      max-width: $max_width_md;
      margin: 0 auto;
      padding: 30px 0 40px;
    }

    @media (min-width: $breakpoint_lg) {
      max-width: $max_width_lg;
      padding: 40px 0;
    }

    @media (min-width: $breakpoint_xl) {
      max-width: $max_width_xl;
      padding: 50px 0;
    }
  }

  &__columns {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 30px;

    @media screen and (orientation: landscape) {
      grid-template-columns: repeat(1, 1fr);
    }

    @media (min-width: $breakpoint_md) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: $breakpoint_lg) {
      gap: 200px;
      grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: $breakpoint_xl) {
      gap: 250px;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  // &__column {}

  &__title {
    font-size: 16px;
    font-weight: 700;

    @media (min-width: $breakpoint_md) {
      font-size: 18px;
    }

    @media (min-width: $breakpoint_lg) {
      font-size: 20px;
    }

    @media (min-width: $breakpoint_xl) {
      font-size: 24px;
    }
  }

  &__list {
    margin-top: 20px;

    @media (min-width: $breakpoint_xl) {
      margin-top: 30px;
    }
  }

  &__item {
    margin-top: 10px;

    @media (min-width: $breakpoint_lg) {
      margin-top: 15px;
      font-size: 16px;
    }

    @media (min-width: $breakpoint_xl) {
      margin-top: 20px;
      font-size: 18px;
    }

    &:first-of-type {
      margin-top: 0;
    }

    a {
      color: #999;
      transition: all 0.5s;
      text-decoration: underline;
      cursor: pointer;

      @media (min-width: $breakpoint_lg) {
        &:hover {
          color: rgb(31, 172, 247);
        }
      }
    }

    img {
      width: 40px;
      transition: all 0.5s;

      @media (min-width: $breakpoint_lg) {
        &:hover {
          opacity: 0.5;
        }
      }
    }
  }

  &__info {
    margin-top: 30px;
    height: auto;
    position: relative;

    @media (min-width: $breakpoint_xl) {
      margin-top: 40px;
    }
  }

  &__logo {
    height: 24px;
    transition: 0.5s;

    @media (min-width: $breakpoint_lg) {
      height: 30px;

      &:hover {
        opacity: 0.5;
      }
    }

    @media (min-width: $breakpoint_xl) {
      height: 36px;
    }

    svg {
      height: 100%;
    }
  }

  &__copy {
    margin-top: 10px;

    &:first-of-type {
      margin-top: 20px;

      @media (min-width: $breakpoint_xl) {
        margin-top: 30px;
      }
    }

    @media (min-width: $breakpoint_md) {
      max-width: 500px;
    }

    @media (min-width: $breakpoint_lg) {
      max-width: 650px;
      font-size: 16px;
    }

    @media (min-width: $breakpoint_xl) {
      margin-top: 20px;
      max-width: 750px;
      font-size: 18px;
    }
  }

  &__developer {
    margin: 10px auto 0;
    display: block;
    width: 70px;

    @media (min-width: $breakpoint_md) {
      position: absolute;
      right: 0;
      top: 0;
      margin: 0;
    }

    @media (min-width: $breakpoint_lg) {
      width: 100px;
    }

    @media (min-width: $breakpoint_xl) {
      width: 120px;
    }

    img {
      vertical-align: middle;
      transition: all 0.5s;

      @media (min-width: $breakpoint_lg) {
        &:hover {
          opacity: 0.5;
        }
      }
    }
  }
}